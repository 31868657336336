import { axiosGet, axiosPost, axiosDelete } from "../axios"

import {
    GET_PATIENT_BP_CUSTOM_PARAMETER_REQUEST,
    GET_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS,
    GET_PATIENT_BP_CUSTOM_PARAMETER_FAIL,
    GET_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST,
    GET_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS,
    GET_PATIENT_OXI_CUSTOM_PARAMETER_FAIL,
    GET_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST,
    GET_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS,
    GET_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL
} from "../types/customParameterConstant";
import { userLogoutAction } from "./userActions";

export const getPatientBpCustomParameter = patientId => async dispatch => {
    try {
        dispatch({
            type: GET_PATIENT_BP_CUSTOM_PARAMETER_REQUEST
        })
        const { data } = await axiosGet(`/api/cs3/patients/specifiedParameter/${patientId}/bp/fetch`)
        dispatch({
            type: GET_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS,
            payload: data?.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_PATIENT_BP_CUSTOM_PARAMETER_FAIL,
            payload: error.response && error?.response?.data?.details?.message
        })
    }
}

// oximeter 

export const getPatientOxiCustomParameter = patientId => async dispatch => {
    try {
        dispatch({
            type: GET_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST
        })
        const { data } = await axiosGet(`/api/cs3/patients/specifiedParameter/${patientId}/oximeter/fetch`)
        dispatch({
            type: GET_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS,
            payload: data?.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_PATIENT_OXI_CUSTOM_PARAMETER_FAIL,
            payload: error.response && error?.response?.data?.details?.message
        })
    }
}

// GLUCOSE METER
export const getPatientGlucoCustomParameter = patientId => async dispatch => {
    try {
        dispatch({
            type: GET_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST
        })
        const { data } = await axiosGet(`/api/cs3/patients/specifiedParameter/${patientId}/glucose/fetch`)
        dispatch({
            type: GET_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS,
            payload: data?.details
        })
        return data
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: GET_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL,
            payload: error.response && error?.response?.data?.details?.message
        })
    }
}