import { Button, Skeleton } from "antd"
import drImg from "../../assets/self-onboarding-doctor.png"
import { useDispatch, useSelector } from "react-redux"
import { getUserProfileAction, userLogoutAction } from "../../redux/actions/userActions"
import { useEffect, useState } from "react"
import Loading from "../layouts/Loading/Loading"
import { Helmet } from "react-helmet"
import { getPatientWelcomeTutorialAction } from "../../redux/actions/selfOnboardPatientAction"
import { useNavigate } from "react-router-dom"
import { decryptBoolean } from "../../utils/encryptionUtils"
import ReactPlayer from 'react-player'

const StartJourney = ({ next, patientId }) => {
  const token = JSON?.parse(localStorage.getItem("token") ?? sessionStorage.getItem("token"))
  patientId = JSON?.parse(localStorage.getItem("id") ?? sessionStorage.getItem("id"))
  const welcomeTutorialString = localStorage.getItem("welcomeTutorial") ?? sessionStorage.getItem("welcomeTutorial")
  const [providerImg, setProviderImage] = useState(drImg)
  const [loadingImg, setLoadingImg] = useState(true)
  const [showVideo, setShowVideo] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, userInfo: patientInfoDetail } = useSelector(state => state.userInfo)
  const {
    loading: welcomeTutorialLoading,
    data: userWelcomeTutorial,
    error
  } = useSelector(state => state.getSelfOnboardedPatient)

  useEffect(() => {
    dispatch(getUserProfileAction(patientId))
    dispatch(getPatientWelcomeTutorialAction(patientId))
  }, [])

  useEffect(() => {
    if (userWelcomeTutorial?.orgImage === null) {
      // if there is no image uploaded by sup admin
      setProviderImage(drImg)
    } else {
      setProviderImage(userWelcomeTutorial?.orgImage)
    }
    
    // Check if video should be shown (need to set on status value)
    if (userWelcomeTutorial?.userTemplateVideo && 
        userWelcomeTutorial?.userTemplateVideo?.videoUrl &&
        userWelcomeTutorial?.userTemplateVideo?.status) {
      setShowVideo(true)
    } else {
      setShowVideo(false)
    }
  }, [userWelcomeTutorial])

  if (!token && !patientId) {
    !window.isMobileVersion
      ? dispatch(userLogoutAction())
      : window.webkit?.messageHandlers?.logout?.postMessage({ value: "harish" })
  }

  if (!window.isMobileVersion) {
    if (!welcomeTutorialString) {
      navigate("/")
    }
    const welcomeTutorial = JSON.parse(welcomeTutorialString)
    if (!decryptBoolean(welcomeTutorial)) {
      navigate("/")
      // Return null to render nothing
      return null
    }
  }

  const onImageLoadHandler = () => {
    setLoadingImg(false)
  }
  
  const renderMedia = () => {
    if (showVideo) {
      return (
        <div className="w-full max-w-[240px] my-8 rounded-lg overflow-hidden">
          <ReactPlayer
            url={userWelcomeTutorial.userTemplateVideo.videoUrl}
            width="100%"
            height="240px"
            controls={true}
            autoplay={true}
            muted={false}
            playing={true}
            playsinline={true}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload'
                }
              }
            }}
          />
        </div>
      )
    }

    return (
      <>
        {loadingImg && (
          <div className="rounded-full">
            <Skeleton.Image width={200} active={true} />
          </div>
        )}
        <img
          className="my-8 rounded-full"
          width="240px"
          src={providerImg}
          loading="lazy"
          alt="doctor"
          onLoad={onImageLoadHandler}
        />
      </>
    )
  }

  return (
    <>
      <Helmet>
        <script>
          {`
            if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.firstPage) {
              window.webkit.messageHandlers.firstPage.postMessage(true);
               } else {
               console.error("window.webkit.messageHandlers.firstPage is undefined. Unable to post message.");
             }
             if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.navigationTitle) {
              window.webkit.messageHandlers.navigationTitle.postMessage("");
               } else {
               console.error("window.webkit.messageHandlers.navigationTitle is undefined. Unable to post message.");
             }
          `}
        </script>
      </Helmet>
      <div className="flex flex-col relative items-center mx-auto py-16 px-3 h-screen w-screen md:w-[30rem] lg:w-[32rem]">
        {!welcomeTutorialLoading && !loading && patientInfoDetail ? (
          <>
            {renderMedia()}

            <div className="w-[289px] text-center">
              <h1>
                Welcome <span className="capitalize">{patientInfoDetail?.firstName}</span> to FitPeo
              </h1>
              <p className="secondary">
                <span className="capitalize">{patientInfoDetail?.orgName}</span> is
                here to welcome you to your own healthcare program. They've chosen FitPeo as your
                personalized healthcare team, focused on giving you better care using
                bluetooth-enabled medical devices.
              </p>
            </div>

            <Button
              className={`rounded-xl ${
                window?.isMobileVersion ? "absolute my-6 bottom-16" : "mt-8"
              }`}
              type="primary"
              onClick={next}>
              Start your Health Journey
            </Button>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </>
  )
}

export default StartJourney
